////////////////////////////////////////////////////////////////
//                  REQUESTS - MEETINGS SECTION
////////////////////////////////////////////////////////////////

// getMeetings() - get a list of all upcoming meetings
// getMeeting() - get get an upcoming meeting by id
// updateMeetingInvite() - update meeting invitation status
// deleteMeetingInvite() - delete meeting invite
// sendEmail() - send email to a user
// sendSMS() - send SMS to a user
// sendStatistics() - send statistics
// createMeeting() - create meeting
// sendInvitation() - send invitation

// getAllUsersInformation() - get all users information
// getUserInformation() - get user information

////////////////////////////////////////////////////////////////

import { getCurrentUserInfo } from "../../@cognito";
import { VTMeetings, VTInviteObject, OtherUsers } from "../../types";
import * as Sentry from "@sentry/nextjs";

// Types for requests with many paremeters
import { MeetingObject } from "./types";

// SETTINGS OBJECT CONTROLING LEVEL OF ERROR REPORTING
const settings = {
  ENVIRONMENT: process.env.NODE_ENV,
  DEBUG: true,
  SENTRY: true,
};

////////////////////////////////////////////////////////////////
//                   GET SECTION
////////////////////////////////////////////////////////////////

/**
 * Request a list of all upcoming meetings
 * @param {number} dateFrom filter meetings by start date (required)
 * @param {number} dateTo  filter meetings by end date (required)
 * @param {string} options  filter parameters ex) options = {status:"rejected" type:"instant"} (optional)
 * @return Response or error message from server
 */
export const getMeetings = async (
  dateFrom: number,
  dateTo: number,
  page: number,
  options?: any
) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  let url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings?type=scheduled`;

  // DATE RANGE
  if (dateTo && dateFrom) {
    url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  }
  // PAGINATION
  if (page) {
    url += `&page=${page}`;
  }

  // OTHER OPTIONS
  if (options) {
    if (options["status"]) {
      url += `&status=${options["status"]}`;
    }
  }

  try {
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: jwt },
    });

    if (res.ok) {
      const meetings = await res.json();
      return meetings;
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Function that returns a meeting by id
 * @param {String | String[] | undefined} id - id of meeting (required)
 * @return Response or error message from server
 */
export const getMeeting = async (id: string | string[] | undefined) => {
  // const user = await getCurrentUserInfo();
  // const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/${id}`;

  try {
    const res = await fetch(url, {
      method: "GET",
      // headers: { Authorization: jwt }, // anyone can GET a meeting by ID, no need to auth
    });

    if (res.ok) {
      const meeting = await res.json();
      return meeting;
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

////////////////////////////////////////////////////////////////
//                   PUT SECTION
////////////////////////////////////////////////////////////////

/**
 * Function that updates a meeting invitation status
 * @param {String} id - id of meeting (required)
 * @param {String} status - status of meeting (required)
 * @param {String} message - message (optional)
 * @param {Boolean} moderator - is moderator (optional)
 * @return Response or error message from server
 */
export const updateMeetingInvite = async (
  id: string,
  status: string,
  message?: string,
  moderator?: boolean
) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/invites/${id}`;

  try {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: jwt,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        status: status,
        message: message || "Status changed",
      }),
    });
    if (res.ok) {
      return res;
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};
/**
 * Function that updates a meeting invitation status
 * @param {string} id - id of meeting (required)
 * @param {Object} invites - array of objects that lists invitee uuids or email addresses (optional)
 * @param {Object} params - other meeting parameters (optional)
 * @return Response or error message from server
 */

export const updateMeeting = async (
  id: string,
  invites: VTInviteObject,
  params: Object
) => {
  const user = await getCurrentUserInfo();
  const jwt = user?.signInUserSession?.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/${id}`;

  try {
    const updatedMeeting = {
      invites: invites,
      ...params,
    };

    const res = await fetch(url, {
      method: "PUT",
      headers: {
        authorization: jwt,
        "Content-type": "application/json",
      },
      body: JSON.stringify(updatedMeeting),
    });

    if (res.ok) {
      return res;
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

// ** NEED TO IMPLEMENT UPDATE MEETING **

////////////////////////////////////////////////////////////////
//                   DELETE SECTION
////////////////////////////////////////////////////////////////

/**
 * Function that deletes a meeting invite
 * @param {String} id - id of meeting (required)
 * @return Response or error message from server
 */
export const deleteMeetingInvite = async (id: string) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/invites/${id}`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: { Authorization: jwt },
    });
    if (res.ok) {
      return res;
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Function that deletes a meeting
 * @param {String} id - id of meeting (required)
 * @return Response or error message from server
 */
export const deleteMeeting = async (id: string) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/${id}`;

  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        authorization: jwt,
      },
    });

    if (res.ok) {
      return true;
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

////////////////////////////////////////////////////////////////
//                   POST SECTION
////////////////////////////////////////////////////////////////

/**
 * Function that creates a new meeting
 * @param {String} name - Name of the meeting (required)
 * @param {Number} start -Unix timestamp of meeting start (required)
 * @param {Number} duration - duration of meeting (required)
 * @param {Object} invites - array of objects that lists invitee uuids or email addresses (optional)
 * @param {Object} params - other meeting parameters (optional)
 * @return Response or error message from server
 */
export const createMeeting = async (
  name: string,
  start: number,
  duration: number,
  invites?: VTInviteObject,
  params?: Object
) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings`;

  try {
    const meeting = {
      name: name,
      start: start,
      duration: duration,
      invites: invites,
      ...params,
    } as MeetingObject;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: jwt,
      },
      body: JSON.stringify(meeting),
    });

    if (res.ok) {
      const meeting = await res.json();
      console.log(meeting);
      return meeting;
    } else {
      const error = await res.text();
      throw error;
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Function that sends a meeting invitation
 * @param {FormData} data - formdata invite object (required)
 * @return Response or error message from server
 * needs updating at some point
 */
export const sendInvitation = async (data: any) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/invites`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: jwt,
        "content-type": "application/json",
      },
      body: data,
    });

    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Function that sends email notification to a user
 * @param {String} meeting_id - meeting id (required)
 * @param {String} email - email (required)
 * @param {String} subject - subject of email (optional)
 * @param {String} base - (optional)
 * @return Response or error message from server
 */
export const sendEmail = async (
  meeting_id: string,
  email: string,
  subject?: string,
  base?: string
) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/email`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: jwt,
      },
      body: JSON.stringify({
        meeting_id: meeting_id,
        email: email,
        base: base,
        subject: subject,
      }),
    });

    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Function that sends sms notification to a user
 * @param {String} meeting_id - meeting id (required)
 * @param {String} endpoint - pone number (required)
 * @param {String} base - (optional)
 * @return Response or error message from server
 */
export const sendSMS = async (
  meeting_id: string,
  endpoint: number,
  base?: string
) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meetings/sms`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: jwt,
      },
      body: JSON.stringify({
        meeting_id: meeting_id,
        endpoint: endpoint,
        base: base,
      }),
    });

    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Function that sends meeting statistics
 * @return Response or error message from server
 */
export const sendStatistics = async (
  display_name?: string,
  rammb?: string,
  cpuid?: string,
  currentcpuspeed?: string,
  connectmode?: string,
  networkcard?: string,
  videodevices?: string,
  audioinputdevices?: string,
  audiooutputdevices?: string,
  meeting_rating?: number,
  video_rating?: number,
  audio_rating?: number
) => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/meeting/statistics`;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: jwt,
      },
      body: JSON.stringify({
        display_name: display_name,
        rammb: rammb,
        cpuid: cpuid,
        currentcpuspeed: currentcpuspeed,
        connectmode: connectmode,
        networkcard: networkcard,
        videodevices: videodevices,
        audioinputdevices: audioinputdevices,
        audiooutputdevices: audiooutputdevices,
        meeting_rating: meeting_rating,
        video_rating: video_rating,
        audio_rating: audio_rating,
      }),
    });

    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

////////////////////////////////////////////////////////////////
//                  OTHER
////////////////////////////////////////////////////////////////

// FUNCTION THAT GETS CURRENT USER INFORMATION
export const getUserInformation = async () => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/users/${user.username}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: jwt },
    });

    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

// FUNCTION THAT GETS ALL USERS INFORMATION
export const getAllUsersInformation = async (
  page: number = 1
): Promise<OtherUsers[]> => {
  const user = await getCurrentUserInfo();
  const jwt = user.signInUserSession.idToken.jwtToken;
  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/users?page=${page}&per_page=300`;

  try {
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: jwt },
    });
    if (res.ok) {
      const json = await res.json();
      const newUsers = json.data?.map((u: OtherUsers) => ({
        ...u,
        name: `${u.first_name} ${u.last_name}`,
        id: u.user_id,
        email: u.email,
      }));
      if (json.current_page === json.last_page) {
        return newUsers;
      }
      return [...newUsers, ...(await getAllUsersInformation(page + 1))];
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }
    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};
